import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
import 'simplebar';
import 'tilt.js';
import TweenLite from "gsap/TweenLite";

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );
var imagesloaded = require('imagesloaded');
jQueryBridget( 'imagesLoaded', imagesloaded, $ );

window.$ = window.jQuery = $;

let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.swiper();
            Page.initialize.tilt();
        },


        /* ---------------------------------------------
            Tilt
        --------------------------------------------- */
        tilt: function(){

            if ( $(window).width() > 1199 ){

                $('.js-tilt-hover').tilt({
                    glare: true,
                    maxTilt: 8,
                    maxGlare: .2,
                });

                $('.js-tilt-hover-sm').tilt({
                    glare: false,
                    maxTilt: 6,
                });

                var request = null;
                var mouse = {
                    x: 0,
                    y: 0
                };
                var cx = window.innerWidth / 2;
                var cy = window.innerHeight / 2;

                $('body').mousemove(function(event) {

                    mouse.x = event.pageX;
                    mouse.y = event.pageY;

                    cancelAnimationFrame(request);
                    request = requestAnimationFrame(update);
                });

                function update() {

                    var dx = mouse.x - cx;
                    var dy = mouse.y - cy;

                    var tiltx = (dy / cy);
                    var tilty = -(dx / cx);

                    if ( $('.tilt-1').length ){
                        TweenLite.to(".tilt-1", 1, {
                            transform: 'translate(' + (tiltx * 5) + 'px, ' + (tilty * 5) + 'px' + ')',
                            ease: Power2.easeOut
                        });
                    }
                }

                $(window).resize(function() {
                    cx = window.innerWidth / 2;
                    cy = window.innerHeight / 2;
                });
            }
        },

        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            var $loading = $body.find(".loading");
            if (window.location.hash) {
                $loading.remove();
                $body.removeClass("loading");
                $body.addClass("loaded");
            }
            else {
                if ($body.hasClass("page-home")) {
                    var $target = $("#loading-target");
                    var $dot = $loading.find(".dot");

                    var x = $target.offset().left;
                    var y = $target.offset().top;

                    /*$body.removeClass("loading");
                    $('html, body').scrollTop(0);
                    $body.addClass("loading");*/

                    $dot.css({top: y + 2, left: x + 2});
                    $loading.addClass("loaded");
                    setTimeout(function () {
                        $body.removeClass("loading");
                        $loading.remove();
                        $body.addClass("loaded");
                    }, 4000);
                } else {
                    $body.removeClass("loading");
                    $body.addClass("loaded");
                }
            }
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){
            // swiper news
            if ( $('#swiper-effects').length > 0 ) {
                let swiperEffectsLength = $('#swiper-effects .swiper-slide').length;
                let swiperEffects = new Swiper('#swiper-effects', {
                    // effect: 'fade',
                    slidesPerView: 1,
                    spaceBetween: 50,
                    simulateTouch: true,
                    // touchRatio: 0,
                    loop: true,
                    slideToClickedSlide: true,
                    allowTouchMove: true,
                    loopAdditionalSlides: 10,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    speed: 1200,
                    pagination: {
                        el: '#swiper-pagination',
                        clickable: true,
                    },
                });
            }
        },

        
        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            AOS.init({
                // Global settings
                disable: $(window).width() < 300, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 400, // values from 0 to 3000, with step 50ms
                duration: 450, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });

            window.addEventListener('load', AOS.refresh);
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            let $offcanvasMenu = $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 600;
                    var offset = $(this).attr('data-offset') || 60;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    $toggleMenu.removeClass("is-active");
                    $offcanvasMenu.removeClass('active');
                    $body.removeClass("menu-is-active");
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            let scroll_amount = 50;

            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });

            $(document).mouseup(function (e) {
                if (!($offcanvasMenu.is(e.target) || 0 !== $offcanvasMenu.has(e.target).length)) {
                    $offcanvasMenu.removeClass('active');
                    $body.removeClass("menu-is-active");
                }
            });

            // scroll event
            if ($window.scrollTop() > scroll_amount) {
                $body.addClass("page-scroll");
            }
            $window.scroll(function () {
                if ($(this).scrollTop() > scroll_amount) {
                    $body.addClass("page-scroll");
                } else {
                    $body.removeClass("page-scroll");
                }
            });
        },
    };
})(jQuery);





